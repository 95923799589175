export default {
  ORDER: {
    isNew: true,
    id: null,
    status: 0,
    price: 0,
    price_discount: 0,
    price_delivery: 0,
    payment_type: 0,
    moving_types: 0,
    user_ps: 1,
    distance: 1,
    is_pay: 0,
    order_type: 0,
    type: 0,
    sale: 0,
    sale_count: 0,
    composition: "",
    photos: "",
    user_phone: "",
    user_call: 0,
    phone: "",
    name: "",
    date: "",
    time: "0:00 - 1:00",
    order_name: "",
    address_a: "",
    elevator_type_a: 0,
    floor_a: "",
    text: "",
    postcard: "",
    comment: "",
  },

  ORDER_TYPES_MANAGER_NAMES: [
    "Новый заказ",
    "ЗАВЕРШИТЬ ЗАКАЗ", 
  ],

  ORDER_TYPES_NAMES: [
    "Новый заказ",
    "В обработке",
    "Букет готов",
    "Доставляется",
    "Выполнен",
    "Оформлен",
    "Отменен",
  ],

  ORDER_PAYMENTS_TYPES_NAMES: [
    "Оплата картой VISA\MasterCard",
    "Наличные",
    "Терминал",
    "Выездной терминал",
    "Оплата курьеру",
    "Перевод на карту Сбербанка",
    "Оплата юр. лицом",
  ],
  ORDER_DELIVERY_TYPES_NAMES: [
    "Доставка",
    "Самовывоз",
  ],
  ORDER_CALL_TYPES_NAMES: [
    "Доставка-сюрприз",
    "Предварительный звонок",
  ],
  ORDER_IS_PAY: [
    "Ожидает",
    "Оплачен",
  ],

  ORDER_SELF_DELIVERY: [
    "ул. Малыгина д. 75",
    "ул. Николая Зелинского д. 17",
    "ул. Газовиков д. 34",
  ],

  ORDER_TIME_TYPE: [
    "0:00 - 1:00",
    "1:00 - 2:00",
    "2:00 - 3:00",
    "3:00 - 4:00",
    "4:00 - 5:00",
    "5:00 - 6:00",
    "6:00 - 7:00",
    "7:00 - 8:00",
    "8:00 - 9:00",
    "9:00 - 10:00",
    "10:00 - 11:00",
    "11:00 - 12:00",
    "12:00 - 13:00",
    "13:00 - 14:00",
    "14:00 - 15:00",
    "15:00 - 16:00",
    "16:00 - 17:00",
    "17:00 - 18:00",
    "18:00 - 19:00",
    "19:00 - 20:00",
    "20:00 - 21:00",
    "21:00 - 22:00",
    "22:00 - 23:00",
    "23:00 - 24:00",
  ],

  ORDER_STATUS_NEW: 0,
  ORDER_STATUS_WAIT: 1,
  ORDER_STATUS_SEND: 2,
  ORDER_STATUS_CONTROL: 3,
  ORDER_STATUS_RAIT: 4,
  ORDER_STATUS_DONE: 4,

  ORDER_TYPES_COLORS: [
    "orange", "green", "cyan", 
    "sky", "violet", "gray", "red",
    "orange", "green", "cyan", 
    "sky", "violet", "gray", "red",
    "orange", "green", "cyan", 
    "sky", "violet", "gray", "red",
  ],

  UPDATE_INFO_NEW_STATUS: 'new_status',
  UPDATE_INFO_CHANGE_INFO: 'change_info',
  UPDATE_INFO_CHANGE_PRICE: 'change_price',
  UPDATE_INFO_NEW_WORKER: 'new_worker',

  ORDER_PAYMENT_TYPE_LIST: [
    {value: "null", text: "Любой способ оплаты"},
    {value: 0, text: "Онлайн оплата"},
    {value: 1, text: "Наличные"},
    {value: 2, text: "Терминал"},
    {value: 3, text: "Выездной терминал"},
    {value: 4, text: "Оплата курьеру"},
    {value: 5, text: "Перевод на карту Сбербанка"},
    {value: 6, text: "Оплата юр. лицом"},
 
  ],
};